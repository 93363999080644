'use client';

import type { Dispatch, ReactNode } from 'react';
import { createContext, useContext, useReducer } from 'react';
import type {
  CurrencyGroup,
  ShippingMethod,
  TAddOn,
  TBulkOrderBillingAddress,
  TSalesOrder,
  TSizingKitAddress,
  TSizingKitOption,
} from 'types/order-portal-api';

export type UnverifiedAddressData = {
  index: number;
  firstName: string;
  lastName: string;
  enteredAddress: string;
  suggestedAddress: string;
};

export type TFormValues = {
  clientId?: string;
  billingEmails?: string[];
  buyerEmails?: string[];
  billingAddress?: TBulkOrderBillingAddress;
  giftNote?: { message?: string; sender?: string };
  membershipOption?: string;
  membershipTitle?: string;
  sizingKitsAddresses?: TSizingKitAddress[];
  sizingKitOption?: TSizingKitOption;
  ringShippingMethods?: ShippingMethod[];
  sizingKitShippingMethods?: ShippingMethod[];
  unverifiedAddresses?: Map<number, UnverifiedAddressData>;
  salesOrderLineItems?: TSalesOrder[];
  flatfileWorkbookId?: string;
  addOns?: {
    saasOptions?: TAddOn[];
    customerSupportOptions?: TAddOn[];
  };
  totalRingQuantity?: number;
  currencyGroups?: CurrencyGroup[];
};

export type TFormActionType = {
  type: 'SAVE_FORM';
  payload: TFormValues;
};

interface IFormWizard {
  data: TFormValues;
}

interface IFormWizardContext extends IFormWizard {
  formDispatch: Dispatch<TFormActionType>;
}

const FormWizardContext = createContext<IFormWizardContext>(
  {} as IFormWizardContext,
);

const initialState: IFormWizard = {
  data: {
    clientId: undefined,
    billingEmails: undefined,
    buyerEmails: undefined,
    giftNote: undefined,
    membershipOption: undefined,
    membershipTitle: undefined,
    sizingKitOption: undefined,
    addOns: undefined,
    totalRingQuantity: undefined,
    currencyGroups: undefined,
  },
};

export const reducer = (state: IFormWizard, action: TFormActionType) => ({
  ...state,
  data: {
    ...state.data,
    ...action.payload,
  },
});

const FormWizardProvider = ({ children }: { readonly children: ReactNode }) => {
  const [formState, formDispatch] = useReducer(reducer, initialState);

  return (
    <FormWizardContext.Provider
      value={{
        ...formState,
        formDispatch,
      }}
    >
      {children}
    </FormWizardContext.Provider>
  );
};

export function useFormWizard() {
  return useContext(FormWizardContext);
}

export default FormWizardProvider;
